import type { IPaymentDetail } from "./paymentDetails";
import type { GatewayStoreType } from "../data/enums/gateway";
import type { IGateway } from "./gateways";
import type { IAddress } from "./addresses";
import type { ICurrency } from "./constants";

export enum PaymentMethodType {
  EXTERNAL_STORE = "external-store",
  GATEWAY_AWAITING_CLIENT = "gateway-awaiting-client",
  GATEWAY_BANK_TRANSFER = "gateway-bank-transfer",
  GATEWAY_CARD = "gateway-card",
  GATEWAY_DIRECT_DEBIT = "gateway-direct-debit",
  GATEWAY_MOBILE = "gateway-mobile",
  GATEWAY_OFFLINE = "gateway-offline",
  GATEWAY_SEPA = "gateway-sepa",
  MANUAL_PAYMENT = "manual-payment",
  PAY_LATER = "pay-later",
  STORED_CARD = "stored-card",
}

export enum PaymentType {
  PAY_IN_FULL = "stored-card",
  PARTIAL_PAYMENT = "partial-payment",
  PAY_LATER = "pay-later",
  MANUAL_PAYMENT = "manual-payment",
}

export type SelectPaymentMethodData =
  | StoredCardData
  | GatewayCardData
  | GatewayData
  | GatewayMobileData
  | GatewayDirectDebitData
  | GatewayExternalCardData
  | GatewayExternalStoreData;

export interface SelectedPaymentMethod {
  amount?: number;
  gatewayProviderStoreType?: GatewayStoreType;
  walletAmount?: number;
  type?: PaymentMethodType;
  dataFunc?: () => Promise<any>;
  data?: SelectPaymentMethodData & {
    cardholder_name?: string;
    external?: boolean;
  };
  auto_payment?: boolean;
  currency_id?: ICurrency["id"];
}

export interface SelectedPaymentMethodStoredCard extends SelectedPaymentMethod {
  gatewayProviderStoreType: GatewayStoreType;
  type: PaymentMethodType.STORED_CARD;
  data: StoredCardData;
}

export interface SelectedPaymentMethodGatewayCard
  extends SelectedPaymentMethod {
  type: PaymentMethodType.GATEWAY_CARD;
  data: GatewayCardData | GatewayExternalCardData;
}

export interface SelectedPaymentMethodGatewayBankTransfer
  extends SelectedPaymentMethod {
  type: PaymentMethodType.GATEWAY_BANK_TRANSFER;
  data: GatewayData;
}

export interface SelectedPaymentMethodGatewayAwaitingClient
  extends SelectedPaymentMethod {
  type: PaymentMethodType.GATEWAY_AWAITING_CLIENT;
  data: GatewayData;
}

export interface SelectedPaymentManualPayment extends SelectedPaymentMethod {
  type: PaymentMethodType.MANUAL_PAYMENT;
  data: ManualPaymentData;
}

export interface SelectedPaymentPayLater extends SelectedPaymentMethod {
  type: PaymentMethodType.PAY_LATER;
}

export interface SelectedPaymentMethodGatewayDirectDebit
  extends SelectedPaymentMethod {
  type: PaymentMethodType.GATEWAY_DIRECT_DEBIT;
  data: GatewayDirectDebitData;
}

export interface SelectedPaymentMethodGatewayOfflinePayment
  extends SelectedPaymentMethod {
  type: PaymentMethodType.GATEWAY_OFFLINE;
  data: GatewayData;
}

export interface SelectedPaymentMethodGatewayMobile
  extends SelectedPaymentMethod {
  type: PaymentMethodType.GATEWAY_MOBILE;
  data: GatewayMobileData;
}

export interface SelectedPaymentMethodExternalStore
  extends SelectedPaymentMethod {
  type: PaymentMethodType.EXTERNAL_STORE;
  data: GatewayExternalStoreData;
}

export interface StoredCardData {
  payment_details_id: IPaymentDetail["id"];
  client_id: IPaymentDetail["client_id"];
  address_id: IAddress["id"];
  gateway_id: IPaymentDetail["gateway_id"];
}

export interface GatewayData {
  gateway_id: IGateway["id"];
  store_on_payment?: boolean;
  store_on_payment_auto_payment?: boolean;
  payment_method_addition?: Record<string, any>;
}

export interface ManualPaymentData {
  gateway_id: IGateway["id"];
  amount: number;
  transaction_id?: string;
}

export interface GatewayCardData extends GatewayData {
  card_type: string;
  card_num: string;
  card_expire_date: string;
  card_cvv: string;
  name: string;
  address_id: IAddress["id"] | null;
  gateway_id: IGateway["id"];
  cardholder_name: string;
  external: false;
  store: boolean;
  auto_payment: boolean;
  requires_address: boolean;
}

export interface GatewayExternalCardData extends GatewayData {
  external: true;
  requires_address: boolean;
}

export interface GatewayMobileData extends GatewayData {
  payer: string;
}

export interface GatewayDirectDebitData extends GatewayData {
  name: string;
}

export interface GatewayExternalStoreData extends GatewayData {
  return_url: string;
  external: boolean;
}
