// --- external
import { spawn } from "xstate";

// --- internal
import itemMachine from "../item.machine";
import { ItemActions as actions } from "./actions";
import services from "./services";

// --- utils
import { map, get, uniqueId } from "lodash-es";

// --- types
// TODO:
import { PhoneTypes } from "./types";
import type { PhoneContext } from "./types";
import { IPhone, ICountry } from "@upmind-automation/types";
import type { JsonSchema, UISchemaElement } from "@jsonforms/core";

// ---
// TODO:
// export const useSchema = ({ country }: PhoneContext) => {
export const useSchema = ({ country }: { country: ICountry }) => {
  const schema = {
    type: "object",
    title: "Address Fields",
    required: ["phone", "type"],
    properties: {
      id: {
        type: ["string", "null"],
        title: "ID",
        description: "The AutoGenerated ID of this Phone.",
        readOnly: true,
      },

      // ---

      phone: {
        type: "object",
        title: "Phone",
        isPhoneNumber: country?.code,
        properties: {
          number: {
            type: ["string", "null"],
            title: "Phone number ( with dailing code )",
          },

          nationalNumber: {
            type: ["string", "null"],
            title: "Phone number",
          },

          countryCallingCode: {
            type: ["string", "null"],
            title: "Country calling code",
          },

          country: {
            type: ["string", "null"],
            title: "Country",
          },
        },
      },

      type: {
        type: ["number", "null"],
        title: "Type",
        oneOf: !PhoneTypes?.length
          ? undefined
          : map(PhoneTypes, ({ value, key }) => {
              return {
                const: key,
                title: value,
              };
            }),
      },

      // ---

      default: {
        type: ["boolean", "null"],
        title: "Make this the default phone?",
      },
    },
    // errorMessage: {
    //   properties: {
    //     phone: "must be a valid phone number"
    //   }
    // }
  };

  // if (id) {
  //   schema.required.push("name");
  //   schema.required.push("type");
  // }

  return schema as JsonSchema;
};

export const useUischema = () => {
  const schema = {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/phone",
        options: {
          autoFocus: true,
          autocomplete: "phone",
          placeholder: "My phone number",
        },
      },
      {
        type: "Control",
        scope: "#/properties/type",
        options: {
          autocomplete: "off",
          placeholder: "Select Type",
        },
      },

      // ---
      // We dont ever show this field as it is set by an action
      // {
      //   type: "Control",
      //   scope: "#/properties/default",
      //   options: {
      //     // toggle: true
      //   }
      // }
    ],
  };

  return schema as UISchemaElement;
};

// ---
export const spawnItem = (model?: IPhone) => {
  try {
    const name = get(model, "id", uniqueId("item_"));
    return spawn(
      itemMachine
        .withConfig({
          actions: actions as any,
          services: services as any,
        })
        .withContext({ model }),
      {
        name,
        sync: true,
      }
    );
  } catch (err) {
    // console.error("PhoneListings", "spawnItem", { model });
    return null;
  }
};
