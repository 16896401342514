// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { cva } from "class-variance-authority";
// -----------------------------------------------------------------------------

export default {
  header: {
    avatar: {
      login: cva("bg-transparent font-bold"),
      session: cva("font-bold"),
    },
  },
  button: cva("rounded-full font-bold", {
    variants: {
      size: {
        xs: "h-8 gap-1 px-3 py-1 text-xs",
        sm: "h-10 gap-2 px-4 py-1 text-sm",
        md: "h-12 gap-2 px-6 py-1 text-md",
        lg: "h-14 gap-2 px-8 py-1 text-lg",
        xl: "h-16 gap-2 px-8 py-1 text-lg",
        icon: "h-10 w-10 gap-2 px-2 py-1",
        badge: "gap-1 rounded-full px-2.5 py-0.5 text-xs font-semibold",
      },
      variant: {
        flat: "border-transparent font-semibold hover:bg-opacity-90",
        outline: "font-font-medium bg-transparent",
        ghost: "border-transparent font-medium",
        link: "!hover:underline border-none !bg-transparent !px-0 font-medium !underline-offset-4",
        tonal: "border-transparent font-semibold",
        inverse: "border-transparent font-semibold",
        control:
          "!hover:bg-opacity-80 !border-input bg-control font-semibold !text-control-foreground shadow-sm ring-offset-background",
      },
      defaultVariants: {
        size: "md",
      },
    },
  }),
  interstitial: {
    title: cva("mb-2 text-center text-3xl font-bold md:text-4xl"),
  },
  title: cva("leading-normal"),
  section: {
    title: cva("leading-normal"),
  },
};
