// import i18n from "@/i18n";

export enum TaxTagTypes {
  PERCENT = 1,
  FIXED = 2,
}

export enum TaxTagBaseTypes {
  NET = "net",
  NET_TAXES = "net_taxes",
}

export enum AppliedTaxTagReason {
  LOCATION_COUNTRY = "location_country",
  LOCATION_REGION = "location_region",
  LOCATION_POST_CODE = "location_post_code",
}

// export function TaxTagTypesMap() {
//   return {
//     [TaxTagTypes.PERCENT]: {
//       id: TaxTagTypes.PERCENT,
//       label: i18n.t("_.percentage")
//     },
//     [TaxTagTypes.FIXED]: {
//       id: TaxTagTypes.FIXED,
//       label: i18n.t("_.fixed_amount")
//     }
//   };
// }

// export function TaxTagBaseTypesMap() {
//   return {
//     [TaxTagBaseTypes.NET]: {
//       id: TaxTagBaseTypes.NET,
//       label: i18n.t("_.subtotal_only"),
//       desc: i18n.t("_sentence.tax.base_type_net_desc")
//     },
//     [TaxTagBaseTypes.NET_TAXES]: {
//       id: TaxTagBaseTypes.NET_TAXES,
//       label: i18n.t("_.subtotal_and_previous_taxes"),
//       desc: i18n.t("_sentence.tax.base_type_net_taxes_desc")
//     }
//   };
// }
