interface Currency {
  code: string;
  name: string;
  country: string;
  country_code: string;
}
export default {
  AED: {
    code: "AED",
    name: "United Arab Emirates Dirham",
    country: "United Arab Emirates",
    country_code: "AE",
  },
  AOA: {
    code: "AOA",
    name: "Angolan Kwanza",
    country: "Angola",
    country_code: "AO",
  },
  AUD: {
    code: "AUD",
    name: "Australian Dollar",
    country: "Australia",
    country_code: "AU",
  },
  AZN: {
    code: "AZN",
    name: "Azerbaijani Manat",
    country: "Azerbaijan",
    country_code: "AZ",
  },
  BDT: {
    code: "BDT",
    name: "Bangladeshi Taka",
    country: "Bangladesh",
    country_code: "BD",
  },
  BGN: {
    code: "BGN",
    name: "Bulgarian Lev",
    country: "Bulgaria",
    country_code: "BG",
  },
  BRL: {
    code: "BRL",
    name: "Brazilian Real",
    country: "Brazil",
    country_code: "BR",
  },
  CAD: {
    code: "CAD",
    name: "Canadian Dollar",
    country: "Canada",
    country_code: "CA",
  },
  CHF: {
    code: "CHF",
    name: "Swiss Franc",
    country: "Switzerland",
    country_code: "CH",
  },
  CLP: {
    code: "CLP",
    name: "Chilean Peso",
    country: "Chile",
    country_code: "CL",
  },
  CNY: {
    code: "CNY",
    name: "Yuan Renminbi",
    country: "China",
    country_code: "CN",
  },
  COP: {
    code: "COP",
    name: "Colombian Peso",
    country: "Colombia",
    country_code: "CO",
  },
  CZK: {
    code: "CZK",
    name: "Czech Koruna",
    country: "Czech Republic",
    country_code: "CZ",
  },
  DKK: {
    code: "DKK",
    name: "Danish Krone",
    country: "Denmark",
    country_code: "DK",
  },
  DZD: {
    code: "DZD",
    name: "Algerian Dinar",
    country: "Algeria",
    country_code: "DZ",
  },
  EGP: {
    code: "EGP",
    name: "Egyptian Pound",
    country: "Egypt",
    country_code: "EG",
  },
  ETB: {
    code: "ETB",
    name: "Ethiopian Birr",
    country: "Ethiopia",
    country_code: "ET",
  },
  EUR: {
    code: "EUR",
    name: "Euro",
    country: "European Union",
    country_code: "EU",
  },
  FJD: {
    code: "FJD",
    name: "Fijian Dollar",
    country: "Fiji",
    country_code: "FJ",
  },
  GBP: {
    code: "GBP",
    name: "British Pound",
    country: "United Kingdom",
    country_code: "GB",
  },
  GHS: {
    code: "GHS",
    name: "Ghanaian Cedi",
    country: "Ghana",
    country_code: "GH",
  },
  HKD: {
    code: "HKD",
    name: "Hong Kong Dollar",
    country: "Hong Kong",
    country_code: "HK",
  },
  HUF: {
    code: "HUF",
    name: "Hungarian Forint",
    country: "Hungary",
    country_code: "HU",
  },
  IDR: {
    code: "IDR",
    name: "Indonesian Rupiah",
    country: "Indonesia",
    country_code: "ID",
  },
  ILS: {
    code: "ILS",
    name: "Israeli New Shekel",
    country: "Isreal",
    country_code: "IL",
  },
  INR: {
    code: "INR",
    name: "Indian Rupee",
    country: "India",
    country_code: "IN",
  },
  ISK: {
    code: "ISK",
    name: "Icelandic Króna",
    country: "Iceland",
    country_code: "IS",
  },
  JPY: {
    code: "JPY",
    name: "Japanese Yen",
    country: "Japan",
    country_code: "JP",
  },
  KES: {
    code: "KES",
    name: "Kenyan Shilling",
    country: "Kenya",
    country_code: "KE",
  },
  MAD: {
    code: "MAD",
    name: "Moroccan Dirham",
    country: "Morocco",
    country_code: "MA",
  },
  MVR: {
    code: "MVR",
    name: "Maldivian Rufiyaa",
    country: "Maldives",
    country_code: "MV",
  },
  MXN: {
    code: "MXN",
    name: "Mexican Peso",
    country: "Mexico",
    country_code: "MX",
  },
  MYR: {
    code: "MYR",
    name: "Malaysian Ringgit",
    country: "Malaysia",
    country_code: "MY",
  },
  MZN: {
    code: "MZN",
    name: "Mozambican Metical",
    country: "Mozambique",
    country_code: "MZ",
  },
  NGN: {
    code: "NGN",
    name: "Nigerian Naira",
    country: "Nigeria",
    country_code: "NG",
  },
  NOK: {
    code: "NOK",
    name: "Norwegian Krone",
    country: "Norway",
    country_code: "NO",
  },
  NPR: {
    code: "NPR",
    name: "Nepalese Rupee",
    country: "Nepal",
    country_code: "NP",
  },
  NZD: {
    code: "NZD",
    name: "New Zealand Dollar",
    country: "New Zealand",
    country_code: "NZ",
  },
  PKR: {
    code: "PKR",
    name: "Pakistani Rupee",
    country: "Pakistan",
    country_code: "PK",
  },
  PLN: {
    code: "PLN",
    name: "Polish Złoty",
    country: "Poland",
    country_code: "PL",
  },
  PYG: {
    code: "PYG",
    name: "Paraguayan Guarani",
    country: "Paraguay",
    country_code: "PY",
  },
  RON: {
    code: "RON",
    name: "Romanian Leu",
    country: "Romania",
    country_code: "RO",
  },
  RUB: {
    code: "RUB",
    name: "Russian Ruble",
    country: "Russia",
    country_code: "RU",
  },
  RWF: {
    code: "RWF",
    name: "Rwandan Franc",
    country: "Rwanda",
    country_code: "RW",
  },
  SEK: {
    code: "SEK",
    name: "Swedish Krona",
    country: "Sweden",
    country_code: "SE",
  },
  SGD: {
    code: "SGD",
    name: "Singapore Dollar",
    country: "Singapore",
    country_code: "SG",
  },
  THB: {
    code: "THB",
    name: "Thai Baht",
    country: "Thailand",
    country_code: "TH",
  },
  TRY: {
    code: "TRY",
    name: "Turkish Lira",
    country: "Turkey",
    country_code: "TR",
  },
  TTD: {
    code: "TTD",
    name: "Trinidad and Tobago Dollar",
    country: "Trinidad and Tobago",
    country_code: "TT",
  },
  TWD: {
    code: "TWD",
    name: "New Taiwan Dollar",
    country: "Taiwan",
    country_code: "TW",
  },
  TZS: {
    code: "TZS",
    name: "Tanzanian Shilling",
    country: "Tanzania",
    country_code: "TZ",
  },
  UAH: {
    code: "UAH",
    name: "Ukrainian Hryvnia",
    country: "Ukraine",
    country_code: "UA",
  },
  UGX: {
    code: "UGX",
    name: "Ugandan Shilling",
    country: "Uganda",
    country_code: "UG",
  },
  USD: {
    code: "USD",
    name: "US Dollar",
    country: "United States",
    country_code: "US",
  },
  XAF: {
    code: "XAF",
    name: "Central African CFA Franc",
    country: "Central African Republic",
    country_code: "CF",
  },
  XOF: {
    code: "XOF",
    name: "West African CFA Franc",
    country: "Benin",
    country_code: "XF",
  },
  ZAR: {
    code: "ZAR",
    name: "South African Rand",
    country: "South Africa",
    country_code: "ZA",
  },
} as Record<string, Currency>;
