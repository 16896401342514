export enum ProductTypes {
  SINGLE_PRODUCT = 1,
  PRODUCT_BUNDLE = 2,
  VOUCHER = 3,
  PRODUCT_OPTION = 4,
  PRODUCT_ATTRIBUTE = 5,
  SUBPRODUCT = 6,
}

export enum ProductCategoryTypes {
  PRODUCT = 1,
  PRODUCT_OPTION = 2,
  PRODUCT_ATTRIBUTE = 3,
}

export enum CurrencyRecalculationTypes {
  NEVER_RECALCULATE = 0,
  ALWAYS_RECALCULATE = 1,
  BRAND_LIKE_RECALCULATE = 2,
}

export enum OrderTypes {
  SINGLE_OPTION = 1,
  QUANTITY_BASED = 2,
  CONFIGURATION_BASED = 3,
}

export enum PurchaseTypes {
  RECURRING = 1,
  ONE_OFF = 0,
}

export enum DefaultPaymentPeriod {
  INHERIT_FROM_BRAND = 0,
  LOWEST_PRICE = 1,
  LOWEST_MONTHLY_PRICE = 2,
  HIGHEST_PRICE = 3,
}

export enum RevenueRecognitionOptions {
  INHERIT = 0,
  IMMEDIATELY = 1,
  OVER_BILLING_CYCLE = 2,
}

export enum RelatedProductsTypes {
  PRODUCT = "product",
  CATEGORY = "products_category",
}

export enum TrialEndActionTypes {
  CONTINUE = 0,
  MIGRATE = 1,
  CANCEL = 2,
}
