import "./assets/main.css";

import { createApp } from "vue";
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";

import Upmind from "@upmind-automation/client-vue";

import { plugins as uiPlugins } from "@upmind-automation/upmind-ui";

// -----------------------------------------------------------------------------

const app = createApp(App);

// ---

Upmind.init({
  app,
  pop: {
    name: import.meta.env.VITE_API_NAME,
    apiUrl: import.meta.env.VITE_API_URL,
    region: import.meta.env.VITE_API_REGION,
  },
  i18n: {
    provider: i18n,
    files: import.meta.env.DEV
      ? import.meta.glob(`@/**/i18n/*-en.json`, { eager: true }) // 'en' only source messages
      : import.meta.glob("@/assets/locales/**/*.json", { eager: true }), // compiled messages
  },
  router: {
    provider: router,
    flows: [],
  },
  analytics: {
    enabled: true,
  },
});

Sentry.init({
  environment: import.meta.env.MODE,
  app,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllInputs: true,
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.thirdPartyErrorFilterIntegration({
      // Specify the application keys that you specified in the Sentry bundler plugin
      filterKeys: ["webcentral-checkout"],

      // Defines how to handle errors that contain third party stack frames.
      // Possible values are:
      // - 'drop-error-if-contains-third-party-frames'
      // - 'drop-error-if-exclusively-contains-third-party-frames'
      // - 'apply-tag-if-contains-third-party-frames'
      // - 'apply-tag-if-exclusively-contains-third-party-frames'
      behaviour: "drop-error-if-contains-third-party-frames",
    }),
  ],

  enabled: import.meta.env.MODE === "production",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    /^https:\/\/webcentral-checkout-dev\.web\.app\.io/,
    /^https:\/\/checkout\.webcentral\.au/,
  ],

  allowUrls: [
    /^https:\/\/webcentral-checkout-dev\.web\.app\.io/,
    /^https:\/\/checkout\.webcentral\.au/,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

// ---

app.use(router);
app.use(i18n);
app.use(uiPlugins.lottie);

app.mount("#app");
