export enum PromotionTypes {
  GLOBAL = 0,
  SELECTED_PRODUCTS = 1,
  INDIVIDUAL = 2,
}

export enum DiscountTypes {
  PERCENTAGE_BASED = 0,
  MONEY_BASED = 1,
}

export enum PromotionDisplayTypes {
  NAME = "name",
  LABEL = "label",
  PERCENTAGE = "percentage",
}
