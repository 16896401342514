import type { IImageCategorySize } from "../store/types";

export enum ImageObjectTypes {
  PRODUCT = "product",
  PRODUCT_CATEGORY = "product_category",
  USER = "user",
  BRAND = "brand",
  BRAND_FAVICON = "favicon",
  BRAND_EMAIL_LOGO = "brandEmailLogo",
  CLIENT = "client",
  ORGANIZATION = "organisation",
  CLIENT_CUSTOM_FIELD = "client_custom_field",
}

export enum ImageSubTypes {
  IMAGE = "image",
  ICON = "icon",
}

export interface IGalleryImageType {
  default: boolean;
  id: string;
  image_category_id: IImageCategorySize["id"];
  hash: string;
  order: number;
  isUploading?: boolean;
  exists?: boolean;
}
